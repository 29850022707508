/**
 * @generated SignedSource<<49f79418c3ea01dc625e64be162b705f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type IneligibilityReason = "INELIGIBILITY_REASON_AUTO_SUPPRESSED" | "INELIGIBILITY_REASON_KNOWN_LITIGATOR" | "INELIGIBILITY_REASON_NOT_FULLY_OPTED_IN" | "INELIGIBILITY_REASON_OPTED_OUT" | "INELIGIBILITY_REASON_UNKNOWN" | "INELIGIBILITY_REASON_USER_SUPPRESSED" | "%future added value";
export type RegionDesignation = "REGION_DESIGNATION_DEFAULT" | "REGION_DESIGNATION_OTHER" | "REGION_DESIGNATION_UNKNOWN" | "%future added value";
export type SubscriptionChannel = "CHANNEL_EMAIL" | "CHANNEL_TEXT" | "CHANNEL_UNKNOWN" | "%future added value";
export type SubscriptionType = "TYPE_CHECKOUT_ABANDONED" | "TYPE_MARKETING" | "TYPE_TRANSACTIONAL" | "TYPE_UNKNOWN" | "%future added value";
export type WellKnownPropertyType = "WELL_KNOWN_PROPERTY_AREA_CODE" | "WELL_KNOWN_PROPERTY_CITY" | "WELL_KNOWN_PROPERTY_CONVERSATIONAL_STATE" | "WELL_KNOWN_PROPERTY_COUNTRY" | "WELL_KNOWN_PROPERTY_DEVICE_TYPE" | "WELL_KNOWN_PROPERTY_FIRST_NAME" | "WELL_KNOWN_PROPERTY_FULL_NAME" | "WELL_KNOWN_PROPERTY_IS_LITIGIOUS" | "WELL_KNOWN_PROPERTY_LANGUAGE" | "WELL_KNOWN_PROPERTY_LAST_NAME" | "WELL_KNOWN_PROPERTY_LOCALE" | "WELL_KNOWN_PROPERTY_MAIL_EXCHANGE" | "WELL_KNOWN_PROPERTY_OPERATING_SYSTEM" | "WELL_KNOWN_PROPERTY_REGION" | "WELL_KNOWN_PROPERTY_TIMEZONE" | "WELL_KNOWN_PROPERTY_TYPES_BESTSELLER_RECOMMENDATION" | "WELL_KNOWN_PROPERTY_TYPES_CATEGORY_BESTSELLER_LAST_PURCHASED" | "WELL_KNOWN_PROPERTY_TYPES_ESTIMATED_LIFETIME_VALUE" | "WELL_KNOWN_PROPERTY_TYPES_IS_PART_OF_AI_JOURNEY" | "WELL_KNOWN_PROPERTY_TYPES_KLAVIYO_LAST_EMAIL_OPEN" | "WELL_KNOWN_PROPERTY_TYPES_MAILCHIMP_MEMBER_RATING" | "WELL_KNOWN_PROPERTY_TYPES_NOTIFY_WHEN_BACK_IN_STOCK" | "WELL_KNOWN_PROPERTY_TYPES_SALESFORCE_SERVICE_CLOUD_CONTACT_ID" | "WELL_KNOWN_PROPERTY_TYPES_SMS_CHURN_PROBABILITY" | "WELL_KNOWN_PROPERTY_TYPES_UNKNOWN" | "WELL_KNOWN_PROPERTY_ZIP" | "%future added value";
export type SubscriberDetail_CRMLib_Query$variables = {
  companyId: string;
  subscriberId: string;
};
export type SubscriberDetail_CRMLib_Query$data = {
  readonly company: {
    readonly timezone?: string;
  };
  readonly subscriber: {
    readonly email?: string;
    readonly id?: string;
    readonly subscribedPhone?: string;
    readonly subscriptions?: {
      readonly signupDate: SerializedDateTime;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"StatusLabel_CRMLib_User" | "getSubscriberDisplayName_CRMLib_User" | "getSubscriberLocation_CRMLib_User" | "getSubscriberRegion_CRMLib_User" | "getUserSubscriptions_CRMLib_User">;
  } | null;
};
export type SubscriberDetail_CRMLib_Query$rawResponse = {
  readonly company: {
    readonly __typename: "Company";
    readonly id: string;
    readonly timezone: string;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
  readonly subscriber: {
    readonly __typename: "User";
    readonly city: string;
    readonly company: {
      readonly id: string;
      readonly regions: ReadonlyArray<{
        readonly designation: RegionDesignation;
        readonly id: string;
        readonly languageTag: string;
      }> | null;
    } | null;
    readonly email: string;
    readonly firstName: string;
    readonly id: string;
    readonly lastName: string;
    readonly state: string;
    readonly subscribedPhone: string;
    readonly subscriptions: {
      readonly signupDate: SerializedDateTime;
      readonly subscriptionEligibilities: ReadonlyArray<{
        readonly eligibility: {
          readonly ineligibilityReason: IneligibilityReason | null;
          readonly isEligible: boolean;
        };
        readonly subscription: {
          readonly channel: SubscriptionChannel;
          readonly type: SubscriptionType;
        };
      }>;
    } | null;
    readonly timezone: string;
    readonly userProperties: {
      readonly propertyData: ReadonlyArray<{
        readonly property: {
          readonly __typename: "WellKnownProperty";
          readonly __isNode: "WellKnownProperty";
          readonly id: string;
          readonly type: WellKnownPropertyType;
        } | {
          readonly __typename: string;
          readonly __isNode: string;
          readonly id: string;
        } | null;
        readonly value: {
          readonly customPropertyValue: {
            readonly __typename: "StringPropertyValue";
            readonly value: string;
          } | {
            readonly __typename: string;
          } | null;
        };
      }>;
    } | null;
    readonly zipcode: string;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
};
export type SubscriberDetail_CRMLib_Query = {
  rawResponse: SubscriberDetail_CRMLib_Query$rawResponse;
  response: SubscriberDetail_CRMLib_Query$data;
  variables: SubscriberDetail_CRMLib_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "subscriberId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timezone",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    (v2/*: any*/)
  ],
  "type": "Company",
  "abstractKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "subscriberId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subscribedPhone",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "signupDate",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "SubscriptionEligibility",
  "kind": "LinkedField",
  "name": "subscriptionEligibilities",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Eligibility",
      "kind": "LinkedField",
      "name": "eligibility",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isEligible",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ineligibilityReason",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Subscription",
      "kind": "LinkedField",
      "name": "subscription",
      "plural": false,
      "selections": [
        (v11/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "channel",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "city",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "zipcode",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "designation",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "languageTag",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v19 = {
  "kind": "InlineFragment",
  "selections": [
    (v11/*: any*/)
  ],
  "type": "WellKnownProperty",
  "abstractKey": null
},
v20 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "type": "StringPropertyValue",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SubscriberDetail_CRMLib_Query",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": "company",
          "args": (v1/*: any*/),
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v3/*: any*/)
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "company"
      },
      {
        "alias": "subscriber",
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SubscriptionConnection",
                "kind": "LinkedField",
                "name": "subscriptions",
                "plural": false,
                "selections": [
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "StatusLabel_CRMLib_User"
              },
              {
                "kind": "InlineDataFragmentSpread",
                "name": "getSubscriberDisplayName_CRMLib_User",
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "args": null,
                "argumentDefinitions": []
              },
              {
                "kind": "InlineDataFragmentSpread",
                "name": "getUserSubscriptions_CRMLib_User",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SubscriptionConnection",
                    "kind": "LinkedField",
                    "name": "subscriptions",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "args": null,
                "argumentDefinitions": []
              },
              {
                "kind": "InlineDataFragmentSpread",
                "name": "getSubscriberLocation_CRMLib_User",
                "selections": [
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/)
                ],
                "args": null,
                "argumentDefinitions": []
              },
              {
                "kind": "InlineDataFragmentSpread",
                "name": "getSubscriberRegion_CRMLib_User",
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Company",
                    "kind": "LinkedField",
                    "name": "company",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Region",
                        "kind": "LinkedField",
                        "name": "regions",
                        "plural": true,
                        "selections": [
                          (v16/*: any*/),
                          (v17/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ListUserPropertiesResponse",
                    "kind": "LinkedField",
                    "name": "userProperties",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserPropertyDefinitionValue",
                        "kind": "LinkedField",
                        "name": "propertyData",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "property",
                            "plural": false,
                            "selections": [
                              (v18/*: any*/),
                              (v19/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserPropertyValue",
                            "kind": "LinkedField",
                            "name": "value",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "customPropertyValue",
                                "plural": false,
                                "selections": [
                                  (v20/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "args": null,
                "argumentDefinitions": []
              }
            ],
            "type": "User",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SubscriberDetail_CRMLib_Query",
    "selections": [
      {
        "alias": "company",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v18/*: any*/),
          (v3/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "subscriber",
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v18/*: any*/),
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SubscriptionConnection",
                "kind": "LinkedField",
                "name": "subscriptions",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/),
              (v10/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "company",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Region",
                    "kind": "LinkedField",
                    "name": "regions",
                    "plural": true,
                    "selections": [
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ListUserPropertiesResponse",
                "kind": "LinkedField",
                "name": "userProperties",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserPropertyDefinitionValue",
                    "kind": "LinkedField",
                    "name": "propertyData",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "property",
                        "plural": false,
                        "selections": [
                          (v18/*: any*/),
                          (v19/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v5/*: any*/)
                            ],
                            "type": "Node",
                            "abstractKey": "__isNode"
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserPropertyValue",
                        "kind": "LinkedField",
                        "name": "value",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "customPropertyValue",
                            "plural": false,
                            "selections": [
                              (v18/*: any*/),
                              (v20/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "User",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a3fd8676e6d07c507d342bdc961dfab7",
    "id": null,
    "metadata": {},
    "name": "SubscriberDetail_CRMLib_Query",
    "operationKind": "query",
    "text": "query SubscriberDetail_CRMLib_Query(\n  $companyId: ID!\n  $subscriberId: ID!\n) {\n  company: node(id: $companyId) {\n    __typename\n    ... on Company {\n      timezone\n    }\n    id\n  }\n  subscriber: node(id: $subscriberId) {\n    __typename\n    ... on User {\n      id\n      email\n      subscribedPhone\n      subscriptions {\n        signupDate\n      }\n      ...StatusLabel_CRMLib_User\n      ...getSubscriberDisplayName_CRMLib_User\n      ...getUserSubscriptions_CRMLib_User\n      ...getSubscriberLocation_CRMLib_User\n      ...getSubscriberRegion_CRMLib_User\n    }\n    id\n  }\n}\n\nfragment StatusLabel_CRMLib_User on User {\n  ...getUserSubscriptions_CRMLib_User\n}\n\nfragment getSubscriberDisplayName_CRMLib_User on User {\n  lastName\n  firstName\n  email\n  subscribedPhone\n}\n\nfragment getSubscriberLocation_CRMLib_User on User {\n  city\n  state\n  zipcode\n}\n\nfragment getSubscriberRegion_CRMLib_User on User {\n  timezone\n  company {\n    regions {\n      designation\n      languageTag\n      id\n    }\n    id\n  }\n  userProperties {\n    propertyData {\n      property {\n        __typename\n        ... on WellKnownProperty {\n          type\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n      value {\n        customPropertyValue {\n          __typename\n          ... on StringPropertyValue {\n            value\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment getUserSubscriptions_CRMLib_User on User {\n  subscriptions {\n    subscriptionEligibilities {\n      eligibility {\n        isEligible\n        ineligibilityReason\n      }\n      subscription {\n        type\n        channel\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "44895351dc3972c726ab11bfa3a563ea";

export default node;
